<template>
  <div class="flex items-center justify-center px-5">
    <div class="p-5 bg-white shadow rounded w-full max-w-md grid grid-cols-2 gap-6">
      <div>
        <div class="w-32 h-32 overflow-hidden rounded-full bg-gray-300 mb-4">
          <img class="w-full" :src="`https://gravatar.com/avatar/${me.email_md5}?s=130&d=mp`" />
        </div>
      </div>
      <div>
        <h1 class="text-xl text-prasset-green-500 mb-2">
          {{ $tuf('profile') }}
        </h1>
        <div class="mb-4">
          <div>{{ me.name }}</div>
          <div>{{ me.email }}</div>
        </div>
        <a target="_blank" href="https://gravatar.com/" class="underline text-prasset-green-400 block">{{ $tuf('change_profile_photo') }}</a>
        <a href="#" @click.prevent="$refs.change_password_modal.show()" class="underline text-prasset-green-400 block">{{ $tuf('change_password') }}</a>
      </div>
    </div>

    <ChangePasswordModal ref="change_password_modal" />
  </div>
</template>

<script>
import useAuth from '@/compositions/useAuth';
import ChangePasswordModal from '@/modules/core/views/components/ChangePasswordModal';

export default {
  components: {
    ChangePasswordModal,
  },

  setup() {
    const { me } = useAuth();

    return {
      me,
    };
  },
};
</script>
